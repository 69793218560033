/* eslint-disable consistent-return */
import axios from 'axios';

export const API_ROOT = process.env.REACT_APP_API_URL;

const http = (baseURL = API_ROOT) => {
  const token = localStorage.getItem('authToken');
  const headers = {
    ContentType: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const client = axios.create({
    baseURL,
    headers,
  });

  // Intercept response object and handleSuccess and Error Object
  function handleSuccess(response) {
    return response;
  }

  /**   Intercept any unauthorized request.
   *    status 401 means either accessToken is expired or invalid
   *    dispatch logout action accordingly     * */
  function handleError(error) {
    // if (error.response?.status !== 500) {
    //   return Promise.reject(error.response);
    // }

    // Firing an apiError event
    document
      .getElementById('root')
      .dispatchEvent(
        new CustomEvent('apiError', { detail: { error: error.response } }),
      );
    return Promise.reject(error.response);
  }

  client.interceptors.response.use(handleSuccess, handleError);

  function get(path) {
    return client.get(path).then((response) => response);
  }

  function post(path, payload) {
    return client.post(path, payload).then((response) => response.data);
  }

  function put(path, payload) {
    return client.put(path, payload).then((response) => response.data);
  }

  function patch(path, payload) {
    return client.patch(path, payload).then((response) => response.data);
  }

  function deleteApi(path, data) {
    if (data) {
      return client.delete(path, data).then((response) => response?.data);
    }
    return client.delete(path).then((response) => response.data);
  }

  return {
    get,
    post,
    put,
    patch,
    delete: deleteApi,
  };
};
export default http;
